import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { archivedReducer } from './archivedProjects';
import { projectListReducer } from './currentProjects';
import { wsReducer } from './wsReducer';

export const rootReducer = combineReducers({
    user: authReducer,
    archived: archivedReducer,
    projectList: projectListReducer,
    websocket: wsReducer,
  });