import React from "react";
import styles from "./pagination.module.css";

const PaginationItem = ({ pageNumber, isActive, onClick }) => {
  return (
    <li className={styles.listItem}>
      <button
        className={`${styles.paginationLink} ${styles.paginationLink_number} ${isActive ? styles.paginationLink_active : ''}`}
        onClick={() => onClick(pageNumber)}
      >
        {pageNumber}
      </button>
    </li>
  );
};

const PaginationNext = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <button
      className={styles.paginationLink}
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage >= totalPages}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 17L15 12L10 7" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  );
};

const PaginationLast = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <button
      className={styles.paginationLink}
      onClick={() => onPageChange(totalPages)}
      disabled={currentPage >= totalPages}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 17L17 12L12 7" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 17L12 12L7 7" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  );
};

export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const createPaginationItems = () => {
    let items = [];
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <PaginationItem key={i} pageNumber={i} isActive={i === currentPage} onClick={onPageChange} />
      );
    }
    return items;
  };

  return (
    <div className={styles.pagination}>
      <ul className={styles.pagesList}>
        {createPaginationItems()}
      </ul>
      <PaginationNext currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
      <PaginationLast currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
    </div>
  );
};