import React from "react";
import styles from "./delete-button.module.css";

export const DeleteButton = (props) => {
  const { disabled } = props;
  return (
    <button
      className={styles.default}
      type="button"
      onClick={props.onClick}
      disabled={disabled}
    >
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 0C16.3037 0 21 4.69629 21 10.5C21 16.3037 16.3037 21 10.5 21C4.69629 21 0 16.3037 0 10.5C0 4.69629 4.69629 0 10.5 0ZM10.5 19.708C15.5654 19.708 19.6875 15.5654 19.6875 10.5C19.6875 5.43457 15.5654 1.3125 10.5 1.3125C5.43457 1.3125 1.3125 5.43457 1.3125 10.5C1.3125 15.5654 5.43457 19.708 10.5 19.708ZM9.78223 11.1562H5.90625C5.53711 11.1562 5.25 10.8691 5.25 10.5C5.25 10.1309 5.53711 9.84375 5.90625 9.84375H15.0938C15.4629 9.84375 15.75 10.1309 15.75 10.5C15.75 10.8691 15.4629 11.1562 15.0938 11.1562H9.78223Z"
          fill="#1E1E1E"
        />
      </svg>
    </button>
  );
};
