import React from 'react';
import './loader.css';
import { createPortal } from 'react-dom';

const Loader = (props) => {
  if (props.type === 'small'){
    return <div className="loader-small">
      <div className="loader-circle"></div>
    </div>
  } else {
    const loaderRoot = document.getElementById('root-messages');
    return createPortal(
    <div className="loader">
      <div className="loader-circle"></div>
    </div>, loaderRoot
    );
  }
};

export default Loader;