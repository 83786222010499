import React from 'react';
import styles from './404.module.css';
import { useNavigate } from 'react-router-dom';
import image from '../../images/404.svg';

export const Page404 = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
   } else {
      navigate('/', { replace: true });
   }
  }
  return (
    <div className={styles.wrapper}>
      <img src={image} alt="404" />
      <p className={styles.text}>Страница не найдена</p>
      <button className={styles.backButton} onClick={() => handleBack()}>Назад</button>
    </div>
  );
};