import React from "react";
import styles from "./input.module.css"
import { useState } from "react";

export const Input = (props) => {
    const { type, placeholder, name, maxLength } = props;
    const [value, setValue] = useState("");
    const handleChange = (evt) => {
        setValue(evt.target.value);
        if (props.onChange) {
            props.onChange(evt);
        }
    } 
  
    return (
        <label className = { styles.inputWrapper }>
            <input 
                type= { type } 
                placeholder = {placeholder} 
                value = { value } 
                className = { `${styles.baseInput}` } 
                onChange={ handleChange }
                name={name}
                maxLength={maxLength}
            />
        </label>
    )
}