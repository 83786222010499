import {
  AUTH_CREATE,
  AUTH_REFRSH,
  AUTH_REGISTER,
  AUTH_USER,
  RESET_PASSWORD_URL,
  SET_PASSWORD_URL,
} from "../../utils/api-routes";
import { refreshToken, requestData } from "../../utils/requestApi";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const REGISTRATION_REQUEST = "REGISTRATION_REQUEST";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILED = "REGISTRATION_FAILED";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const TOKEN_REQUEST = "TOKEN_REQUEST";
export const TOKEN_SUCCESS = "TOKEN_SUCCESS";
export const TOKEN_FAILED = "TOKEN_FAILED";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";

const loginUrl = AUTH_CREATE;
const registerUrl = AUTH_REGISTER;
const tokenRefreshUrl = AUTH_REFRSH;
const getUserUrl = AUTH_USER;
const resetPassUrl = RESET_PASSWORD_URL;
const setPassUrl = SET_PASSWORD_URL;

export const login = (user) => {
  return function (dispatch) {
    dispatch({
      type: LOGIN_REQUEST,
    });
    requestData(loginUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user.email,
        password: user.password,
      }),
    })
      .then((res) => {
        if (res && res.access) {
          const token = res.access;
          localStorage.setItem("refreshToken", res.refresh);
          dispatch({
            type: LOGIN_SUCCESS,
            token: token,
          });
        } else {
          return Promise.reject(`Ошибка ${res.status}`);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: LOGIN_FAILED,
        });
      });
  };
};

export const logout = () => {
  return function (dispatch) {
    localStorage.removeItem("refreshToken");
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  };
};

export const registration = (user) => {
  return function (dispatch) {
    dispatch({
      type: REGISTRATION_REQUEST,
    });
    requestData(registerUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user.email,
        password: user.password,
        first_name: user.name,
        post: user.post,
        organization: user.organization,
      }),
    })
      .then((res) => {
        if (res && res.id) {
          dispatch({
            type: REGISTRATION_SUCCESS,
            name: res.name,
            email: res.email,
            id: res.id,
            post: res.post,
            organization: res.organization,
            registrationSuccess: true,
          });
        } else {
          return Promise.reject(`Ошибка ${res.status}`);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: REGISTRATION_FAILED,
        });
      });
  };
};

export const tokenRefresh = () => {
  return async function (dispatch) {
    dispatch({
      type: TOKEN_REQUEST,
    });
    const refreshToken = localStorage.getItem("refreshToken");
    requestData(tokenRefreshUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: refreshToken,
      }),
    })
      .then((res) => {
        if (res && res.access) {
          const token = res.access;
          localStorage.setItem("refreshToken", res.refresh);
          dispatch({
            type: TOKEN_SUCCESS,
            token: token,
          });
        } else {
          return Promise.reject(`Ошибка ${res.status}`);
        }
      })
      .catch((error) => {
        console.error(error);
        localStorage.removeItem("refreshToken");
        dispatch({
          type: TOKEN_FAILED,
        });
      });
  };
};

export const getUserData = (token) => {
  return async function (dispatch) {
    dispatch({
      type: GET_USER_REQUEST,
    });
    requestData(getUserUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res && res.id) {
          dispatch({
            type: GET_USER_SUCCESS,
            name: res.first_name,
            email: res.email,
            id: res.id,
            organization: res.organization,
            post: res.post
          });
        } else {
          return Promise.reject(`Ошибка ${res.status}`);
        }
      })
      .catch(async () => {
        const newToken = await refreshToken();
        if (!newToken.access) {
          Promise.reject(newToken);
        }
        requestData(getUserUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer  ${token}`,
          },
        })
          .then((res) => {
            if (res && res.id) {
              dispatch({
                type: GET_USER_SUCCESS,
                name: res.first_name,
                email: res.email,
                id: res.id,
                organization: res.organization,
                post: res.post
              });
            } else {
              return Promise.reject(`Ошибка ${res.status}`);
            }
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: GET_USER_FAILED,
            });
          });
      });
  };
};

export const restorePassword = (email) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });
    requestData(resetPassUrl, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((res) => {
        if (res && res.status) {
          dispatch({
            type: RESET_PASSWORD_SUCCESS,
          });
        } else {
          return Promise.reject(`Ошибка ${res.status}`);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: RESET_PASSWORD_FAILED,
        });
      });
  };
};
export const setNewPassword = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PASSWORD_REQUEST,
    });
    requestData(setPassUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        new_password: data.password,
        token: data.reset_token,
      }),
    })
      .then((res) => {
        if (res && res.status) {
          dispatch({
            type: SET_PASSWORD_SUCCESS,
            password: data.new_password,
            token: data.token
          });
        } else {
          return Promise.reject(`Ошибка ${res.status}`);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: SET_PASSWORD_FAILED,
        });
      });
  };
};
