import React from "react";
import styles from "./header.module.css";
import { LogoutIcon } from "../ui/LogoutIcon/LogoutIcon";
import { logout } from "../../services/actions/auth";
import { useSelector, useDispatch } from "react-redux";

export const HeaderCabinet = () => {
  const { name } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <nav className={styles.navCabinet}>
      <ul className={styles.cabinetList}>
        {name && <li className={styles.userName}>{name}</li>}
        <li>
          <LogoutIcon
            onClick={handleLogout}
            className={styles.logout}
          ></LogoutIcon>
        </li>
      </ul>
    </nav>
  );
};
