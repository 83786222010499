import { Link } from 'react-router-dom';
import styles from './report.module.css';
import { ROUTES } from '../../utils/routes';
import backArrow from '../../images/back-arrow.svg'
import { useDispatch, useSelector } from 'react-redux';
import { fileDownload } from '../../services/actions/archivedProjects';
import Loader from '../ui/Loader/Loader';
export const ReportControls = (props)=>{
    const {id} = props;
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const {fileDownloadRequest} = useSelector(state => state.archived);
    const handleClick = (type) => {
        dispatch(fileDownload(token, id, type));
    }
    return (
        <div className={styles.controlsWrapper}>
            <Link to={ROUTES.MAIN} className={styles.backLink}><img src={backArrow} alt='Back'></img>Назад</Link>
            <div className={styles.buttonsWrapper}>
                <Link className={styles.reportButton} onClick={() => handleClick('get_fasta')}>Экспортировать fasta</Link>
                <Link className={styles.reportButton} onClick={() => handleClick('get_pdf')}>Сгенерировать отчёт в pdf</Link>
                <Link className={styles.reportButton} onClick={() => handleClick('get_report_file')}>Сгенерировать ответ в json</Link>
                <Link className={styles.reportButton} onClick={() => handleClick('get_all_files')}>Экспортировать файлы проекта</Link>
            </div>
            {fileDownloadRequest && <Loader />}
        </div>
    )
}