import React from "react";
import "./StatusIcon.css"; // Импортируйте стили

const StatusIcon = ({ status }) => {
  return (
    <div className={`icon-container ${status ? "active" : "inactive"}`}>
      {status ? (
        <span className="check-icon">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.87493 9.17498L1.81243 6.11248C1.73235 6.03142 1.63697 5.96706 1.53183 5.92313C1.42669 5.87921 1.31387 5.85659 1.19993 5.85659C1.08598 5.85659 0.973167 5.87921 0.868026 5.92313C0.762886 5.96706 0.66751 6.03142 0.587427 6.11248C0.506368 6.19256 0.44201 6.28793 0.398086 6.39308C0.354161 6.49822 0.331543 6.61103 0.331543 6.72498C0.331543 6.83892 0.354161 6.95174 0.398086 7.05688C0.44201 7.16202 0.506368 7.25739 0.587427 7.33748L4.25368 11.0037C4.59493 11.345 5.14618 11.345 5.48743 11.0037L14.7624 1.73748C14.8435 1.65739 14.9078 1.56202 14.9518 1.45688C14.9957 1.35174 15.0183 1.23892 15.0183 1.12498C15.0183 1.01103 14.9957 0.898216 14.9518 0.793075C14.9078 0.687935 14.8435 0.592559 14.7624 0.512476C14.6823 0.431417 14.587 0.367059 14.4818 0.323134C14.3767 0.27921 14.2639 0.256592 14.1499 0.256592C14.036 0.256592 13.9232 0.27921 13.818 0.323134C13.7129 0.367059 13.6175 0.431417 13.5374 0.512476L4.87493 9.17498Z"
              fill="white"
            />
          </svg>
        </span> // Иконка для true
      ) : (
        <span className="close-icon">
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.787598 8.2126L8.21285 0.787354M8.21285 8.2126L0.787598 0.787354"
              stroke="white"
              strokeWidth="1.3125"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span> // Иконка для false
      )}
    </div>
  );
};

export default StatusIcon;
