import { AUTH_REFRSH, GET_UPLOAD_URL, CREATE_PROJECT_URL, START_PROJECT_URL } from "./api-routes";

const checkResponse = (response) => {
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(`Ошибка ${response.status}`);
};

export const requestData = async (url, options) => {
  const response = await fetch(url, options);
  return checkResponse(response);
};

export const refreshToken = async () => {
  const res = await requestData(AUTH_REFRSH, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      refresh: localStorage.getItem("refreshToken"),
    }),
  });
  return res;
};
export const getUploadUrl = async (id, fileName, token) => {
  const url = `${CREATE_PROJECT_URL}${id}${GET_UPLOAD_URL}`;
  try {
    const response = await requestData(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer  ${token}`,
      },
      body: JSON.stringify({
        fastq_name: fileName,
      }),
    });
    return response.upload_url;
  } catch (error) {
    console.error('Ошибка при получении URL загрузки:', error);
    throw error;
  }
};
export const startProject = async (token, id) => {
  const url = `${CREATE_PROJECT_URL}${id}${START_PROJECT_URL}`;
  fetch(url, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer  ${token}`,
    },
  }).then((res) => {
    return res;
  }).catch((err) => {
    console.error(err);
  });
};
