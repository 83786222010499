/* API Routes */
const DOMAIN_DEV = 'https://rezistscreen.xenus.dev/';
const DOMAIN_PROD = '/';
export const DOMAIN = DOMAIN_PROD;
/* About API */
export const ABOUT_URL = `${DOMAIN}api/about/`
/* Auth APIs */
export const AUTH_CREATE = `${DOMAIN}api/auth/jwt/create/`;
export const AUTH_REFRSH = `${DOMAIN}api/auth/jwt/refresh/`;
export const AUTH_VERIFY = `${DOMAIN}api/auth/jwt/verify/`;
export const AUTH_REGISTER = `${DOMAIN}api/users/`;
export const AUTH_USER = `${DOMAIN}api/users/me/`;

/* Reset Password */
export const RESET_PASSWORD_URL = `${DOMAIN}api/users/reset_password/`
export const SET_PASSWORD_URL = `${DOMAIN}api/users/set_password/`

/* Projects */
export const GET_ARCHIVED_URL = `${DOMAIN}api/projects/archived/`;
export const GET_PROJECTS_URL = `${DOMAIN}api/projects/`;
export const CREATE_PROJECT_URL = `${DOMAIN}api/projects/`;
export const GET_UPLOAD_URL = `/get_upload_link/`;
export const GET_REPORT_URL = `/get_report_json/`;
export const START_PROJECT_URL = '/start_project/';
export const WS_URL = `${DOMAIN}ws/user`;
