import React from "react";
import { Logo } from "../ui/Logo/Logo";
import styles from "./header.module.css";
import { HeaderNav } from "./HeaderNav";
import { HeaderCabinet } from "./HeaderCabinet";

export const Header = () => {
  return (
    <header className={styles.header}>
      <div className="glass-effect"></div>
      <Logo type="minimized" />
      <HeaderNav />
      <HeaderCabinet />
    </header>
  );
};
