import React from "react";
import { Header } from "../Header/Header";
import styles from './main.module.css'
import { ProjectAnalysis } from "../ProjectAnalysis/ProjectAnalysis";
import { ProjectHistory } from "../ProjectHistory/ProjectHistory";

export const Main = () => {

    return (
        <div className= { styles.container }>
            <Header />
            <main className={styles.main}>
                <ProjectAnalysis />
                <ProjectHistory />
            </main>
        </div>
    )
}