import {
    GET_ARCHIVED_REQUEST,
    GET_ARCHIVED_SUCCESS,
    GET_ARCHIVED_FAILED,
    DELETE_PROJECT_REQUEST,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAILED,
    GET_REPORT_REQUEST,
    GET_REPORT_SUCCESS,
    GET_REPORT_FAILED,
    FILE_DOWNLOAD_REQUEST,
    FILE_DOWNLOAD_SUCCESS,
    FILE_DOWNLOAD_FAILED,
    ADD_PROJECT_TO_ARCHIVED,
} from '../actions/archivedProjects';
import { INITIAL_STATE } from '../../utils/initial-state';

export const archivedReducer = (state = INITIAL_STATE.archived, action) => {
  switch (action.type) {
    case GET_ARCHIVED_REQUEST: {
      return {
        ...state,
        itemsRequest: true,
      };
    }
    case GET_ARCHIVED_SUCCESS: {
      const sortedItems = action.items.sort((a, b) => b.id - a.id);
      return {
        ...state,
        itemsFailed: false,
        items: sortedItems,
        itemsRequest: false,
      };
    }
    case GET_ARCHIVED_FAILED: {
      return { ...state, itemsFailed: true, itemsRequest: false };
    }
    case DELETE_PROJECT_REQUEST: {
      return {
        ...state,
        deleteProjectRequest: true,
      };
    }
    case DELETE_PROJECT_SUCCESS: {
      return {
        ...state,
        deleteProjectRequest: false,
      };
    }
    case DELETE_PROJECT_FAILED: {
      return {
        ...state,
        deleteProjectFailed: true,
        deleteProjectRequest: false,
      };
    }
    case GET_REPORT_REQUEST: {
      return {
        ...state,
        reportRequest: true,
        reportData: null,
      };
    }
    case GET_REPORT_SUCCESS: {
      return {
        ...state,
        reportSuccess: true,
        reportRequest: false,
        reportData: action.payload,
      };
    }
    case GET_REPORT_FAILED: {
      return {
        ...state,
        reportFailed: true,
        reportRequest: false,
        reportData: null,
      };
    }
    case FILE_DOWNLOAD_REQUEST: {
      return {
        ...state,
        fileDownloadRequest: true,
        fileDownloadUrl: '',
      };
    }
    case FILE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        fileDownloadSuccess: true,
        fileDownloadRequest: false,
        fileDownloadUrl: action.payload,
      };
    }
    case FILE_DOWNLOAD_FAILED: {
      return {
        ...state,
        fileDownloadFailed: true,
        fileDownloadRequest: false,
        fileDownloadUrl: '',
      };
    }
    case ADD_PROJECT_TO_ARCHIVED: {
      return {
        ...state,
        items: [action.payload, ...state.items],
      };
    }
    default:
      return state;
  }
};