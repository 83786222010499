import React, { useState } from "react";
import styles from "./header.module.css";
import { Link } from "react-router-dom";
import Modal from "../Modals/Modal";
import { About } from "../About/About";

export const HeaderNav = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const handleNavClick = (evt) => {
    setModalContent(evt.target.title);
    setModalOpen(true);
  };
  return (
    <>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <li>
            <Link
              className={`${styles.link} link-default`}
              to="/docs/help"
              title="Help"
              target="_blank"
            >
              Помощь
            </Link>
          </li>
          <li>
            <Link
              className={`${styles.link} link-default`}
              onClick={handleNavClick}
              title="About"
            >
              О программе
            </Link>
          </li>
        </ul>
      </nav>
      {isModalOpen && modalContent === "About" && (
        <Modal type="info" onClose={() => setModalOpen(false)}>
          <About />
        </Modal>
      )}
    </>
  );
};
