import React from "react";
import styles from './logout-icon.module.css';

export const LogoutIcon = (props) => {
    return (
        <button 
            onClick={ props.onClick }
            className={styles.logout}
        >
            <svg width="25" height="29" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.375 1.50171H1.125V24.2517C1.125 25.1137 1.46741 25.9403 2.0769 26.5498C2.6864 27.1593 3.51305 27.5017 4.375 27.5017H17.375M19 19.3767L23.875 14.5017M23.875 14.5017L19 9.62671M23.875 14.5017H7.625" stroke="#EFF0F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </button>
    )
}
