import { useState } from "react";
import { Logo } from "../../components/ui/Logo/Logo";
import { PasswordInput } from "../../components/ui/PasswordInput/PasswordInput";
import styles from "./set-password.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setNewPassword } from "../../services/actions/auth";
import { Button } from "../../components/ui/Button/Button";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import Loader from "../../components/ui/Loader/Loader";
import { VALIDATION_ERRORS } from "../../utils/constants";

export const SetPassword = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const reset_token = searchParams.get("reset_token");
  const { setPasswdRequest, setPasswdSuccess, setPasswdFailed } = useSelector(
    (store) => store.user
  );

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };
  const validate = () => {
    let validationErrors = {};
    const { password, confirmPassword } = formData;

    if (!formData.password) {
      validationErrors.password = VALIDATION_ERRORS.PASSWORD;
    } else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[a-z]).{8,}$/.test(password)) {
      validationErrors.password = VALIDATION_ERRORS.PASSWORD_WRONG;
    }
    if (password !== confirmPassword) {
      validationErrors.confirmPassword = VALIDATION_ERRORS.CONFIRM_PASSWORD;
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const isFormValid = Object.values(formData).every(Boolean);
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (validate()) {
      dispatch(
        setNewPassword({
          reset_token: reset_token,
          password: formData.password,
        })
      );
    }
  };
  return (
    <>
      {reset_token ? (
        <>
          <Logo />
          <p></p>
          {!setPasswdFailed && !setPasswdSuccess && (
            <form onSubmit={handleSubmit} className={styles.setForm}>
              <span>
                <PasswordInput
                  placeholder="Пароль"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <span className={styles.error}>{errors.password}</span>
                )}
              </span>
              <span>
                <PasswordInput
                  placeholder="Повторите пароль"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                {errors.confirmPassword && (
                  <span className={styles.error}>{errors.confirmPassword}</span>
                )}
              </span>
              <Button value="Сохранить" type="submit" disabled={!isFormValid} />
            </form>
          )}
          {setPasswdRequest && <Loader />}
          {setPasswdFailed && (
            <p className={styles.errorMsg}>
              Что-то пошло не так, попробуйте заново
            </p>
          )}
          {setPasswdSuccess && (
            <>
              <p className={styles.successMsg}>
                Вы успешно обновили пароль. Введите новые данные для входа.
              </p>
              <Link to={ROUTES.LOGIN} className={styles.loginLink}>Страница входа</Link>
            </>
          )}
        </>
      ) : (
        <Navigate to={ROUTES.FORGOT_PASS} />
      )}
    </>
  );
};
