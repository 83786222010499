import React from "react";
import logo from '../../../images/logo.svg';
import logoMin from '../../../images/logo-min.svg'
import styles from './logo.module.css'; 
import { useLocation, Link } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";


export const Logo = (props) => {
    const source = props.type === "minimized" ? logoMin : logo;
    const location = useLocation();
    const anchorRef = location.pathname === ROUTES.MAIN ? '' : ROUTES.MAIN;
    return (
        <Link
            to={anchorRef}
            alt="Logo"
            title="Logo"
        >
              <img src={ source } className={ props.type === "minimized" ? styles.logoMin : styles.logo } alt="logo"/>
        </Link>
    )
}