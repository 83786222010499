import { GET_ARCHIVED_URL, DOMAIN, GET_REPORT_URL, GET_PROJECTS_URL } from '../../utils/api-routes';
import { requestData } from '../../utils/requestApi';

export const GET_ARCHIVED_REQUEST = 'GET_ARCHIVED_REQUEST';
export const GET_ARCHIVED_SUCCESS = 'GET_ARCHIVED_SUCCESS';
export const GET_ARCHIVED_FAILED = 'GET_ARCHIVED_FAILED';
export const DELETE_PROJECT_REQUEST = 'DELETE_ARCHIVED_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_ARCHIVED_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILED = 'DELETE_ARCHIVED_PROJECT_FAILED';
export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILED = 'GET_REPORT_FAILED';
export const FILE_DOWNLOAD_REQUEST = 'FILE_DOWNLOAD_REQUEST';
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS';
export const FILE_DOWNLOAD_FAILED = 'FILE_DOWNLOAD_FAILED';
export const FILE_DOWNLOAD_URL = 'FILE_DOWNLOAD_URL';
export const ADD_PROJECT_TO_ARCHIVED = 'ADD_PROJECT_TO_ARCHIVED';

export function getArchivedProjects(token) {
  return function (dispatch) {
    dispatch({
      type: GET_ARCHIVED_REQUEST,
    });
    requestData(GET_ARCHIVED_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer  ${token}`,
      },
    })
      .then((res) => {
        if (res) {
          dispatch({
            type: GET_ARCHIVED_SUCCESS,
            items: res,
          });
        } else {
          return Promise.reject(`Ошибка ${res.status}`);
        }
      })
      .catch(
        dispatch({
          type: GET_ARCHIVED_FAILED,
        })
      );
  };
}

export function deleteArchivedProject(token, id) {
  return function (dispatch) {
    dispatch({
      type: DELETE_PROJECT_REQUEST,
    });
    const url = `${DOMAIN}api/projects/${id}/delete/`;
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer  ${token}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    }).then((res) => {
      if (res.status === 204) {
        dispatch({ type: DELETE_PROJECT_SUCCESS });
        dispatch(getArchivedProjects(token));
      }
    })
      .catch((err) => {
        dispatch({ type: DELETE_PROJECT_FAILED });
        return Promise.reject(`Ошибка ${err}`);
      });
  }
}
export function getReport(token, id) {
  return function (dispatch) {
    dispatch({
      type: GET_REPORT_REQUEST,
    });

    const url = `${GET_PROJECTS_URL}${id}${GET_REPORT_URL}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return Promise.reject(`Ошибка ${res.status}`);
      }
    })
    .then((data) => {
      dispatch({
        type: GET_REPORT_SUCCESS,
        payload: data.report_json, 
      });
    })
    .catch((error) => {
      console.error('Ошибка при получении отчета:', error);
      
      dispatch({
        type: GET_REPORT_FAILED,
      });
    });
  }
}
export function fileDownload(token, id, type) {
  return function (dispatch) {
    dispatch({
      type: FILE_DOWNLOAD_REQUEST,
    });
    const url = `${GET_PROJECTS_URL}${id}/${type}/`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return Promise.reject(`Ошибка ${res.status}`);
      }
    }).then((data) => {
      dispatch({
        type: FILE_DOWNLOAD_SUCCESS,
      });
      window.open(data.upload_url, '_blank');
    })
    .catch((error) => {
      console.error('Ошибка при скачивании файла:', error);
      dispatch({
        type: FILE_DOWNLOAD_FAILED,
      });
    });
  }
}
