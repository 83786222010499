import styles from "./report.module.css";
import { AccordeonBarMarker } from "./AccordeonBarMarker";

export const AccordeonBar = (props) => {
    const { type, mutations } = props;
    const styleModifier = type === 'PR' ? styles.accordeonBarPR : type === 'PI' ? styles.accordeonBarPI : type === 'NNRTI' ? styles.accordeonBarNNRTI : '';
    const getOffset = (mutation) => {
        const matches = mutation.match(/\d+/);
        if (matches) {
            console.log(mutation, matches)
            return Math.round((parseInt(matches[0], 10) * 100) / 250);
        }
        return 0;
    };
    return (
        <div className={`${styles.accordeonBar} ${styleModifier}`}>
            {props.mutations && props.mutations.length > 0 && (
                mutations.map((mutation, index) => (
                        <span key={index} className={styles.accordeonBarMutations} style={{ marginLeft: getOffset(mutation[0]) + '%', position: 'absolute' }}>
                            <AccordeonBarMarker mutation={mutation[0]} />
                        </span>
                ))
            )}
            {!props.mutations && <span className={styles.accordeonBarMutations}>Не удалось определить</span>}
        </div>
    );
}

