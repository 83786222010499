import { AccordeonItem } from './AccordeonItem';
import { ACCORDEON_DATA } from '../../utils/constants';
import styles from './report.module.css';

export const Accordeon = (props) => {
    const data = ACCORDEON_DATA;
    const {drugs} = props;
    return (
        <section className={styles.accordeon}>
            {Object.keys(data).map((key) => (
                <AccordeonItem key={key} title={data[key].title} preparats={data[key].preparats} drugs={drugs} type={key}/>
            ))}
        </section>
    )
}