export const INITIAL_STATE = {
  projectList: {
    items: [],
    getProjectsRequest: false,
    getProjectsSuccess: false,
    getProjectsFailed: false,
    moveToArchivedRequest: false,
    moveToArchivedSuccess: false,
    moveToArchivedFailed: false,
    createProjectRequest: false,
    createProjectSuccess: false,
    createProjectFailed: false,
    filesUploadRequest: false,
    filesUploadSuccess: false,
    filesUploadFailed: false,
    filesUploadProgress: 0,
    createProjectId: 0,
  },
  archived: {
    items: [],
    itemsRequest: false,
    itemsFailed: false,
    reportRequest: false,
    reportSuccess: false,
    reportFailed: false,
    fileDownloadRequest: false,
    fileDownloadSuccess: false,
    fileDownloadFailed: false,
    fileDownloadUrl: '',
    reportData: {},
  },
  user: {
    auth: false,
    id: 0,
    name: "",
    organization: "",
    post: "",
    email: "",
    password: "",
    token: "",
    projects: {},
    loginRequest: false,
    loginSuccess: false,
    loginFailed: false,
    registrationRequest: false,
    registrationSuccess: false,
    registrationFailed: false,
    logoutRequest: false,
    logoutFailed: false,
    getUserRequest: false,
    getUserFailed: false,
    updateUserRequest: false,
    updateUserFailed: false,
    tokenRequest: false,
    tokenFailed: false,
    resetRequest: false,
    resetSuccess: false,
    resetFailed: false,
    setPasswdRequest: false,
    setPasswdSuccess: false,
    setPasswdFailed: false,
  },
};
