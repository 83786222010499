import React from "react";
import styles from "./search-input.module.css"

export const SearchInput = (props) => {
    const {name, value, onChange} = props;
    return (
        <label className = { styles.inputWrapper }>
            <input 
                value = { value } 
                className = { `${styles.baseInput}` } 
                onChange={ onChange }
                name={name}
            />
             <button>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.75 20.625C17.547 20.625 20.625 17.547 20.625 13.75C20.625 9.95304 17.547 6.875 13.75 6.875C9.95304 6.875 6.875 9.95304 6.875 13.75C6.875 17.547 9.95304 20.625 13.75 20.625Z" stroke="#252D61" strokeWidth="2"/>
                <path d="M18.75 18.75L23.75 23.75" stroke="#252D61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </label>
    )
}