import React from 'react';
import styles from './progress-bar.module.css';

const ProgressBar = ({ progress, title }) => {
    return (
        <div className={styles.progressContainer}>
            <div className={styles.progressBar}>
                <div className={styles.progressTitle}>{`${progress}% ${title}`}</div>
                <div className={styles.progressValue} style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    );
};

export default ProgressBar;