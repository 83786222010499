import React, { useState } from "react";
import styles from './checkbox.module.css'

export const CheckBox = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (evt) => {
        setIsChecked(!isChecked)
        if (props.onChange) {
            props.onChange(evt);
        }
    }
    return (
        <div className={styles.checkboxBlock}>
            <label className={styles.label}>
                <input
                    className={styles.input}
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    name={props.name}
                />
                <span
                    className={`${styles.checkbox} ${isChecked ? styles.checkboxActive : ""}`}
                    aria-hidden="true"
                />
                {props.labelText}
            </label>
            {props.children && <p className={styles.text}>{props.children}</p>}
        </div>
    )
}