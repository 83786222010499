import React from "react";
import { Button } from "../ui/Button/Button";
import styles from './delete-project.module.css';
import { DELETE_PROJECT_TEXT } from "../../utils/constants";

export const DeleteProject = (props) => {
     return (
        <form className={styles.form}>
            <p className={ styles.text }>
                { DELETE_PROJECT_TEXT }
            </p>
            <div className={ styles.controls }>
                <Button value = "Да, удалить" onClick={() => props.onConfirm(props.id)}/>
                <Button value = "Нет, отменить" transparent='true' onClick={ props.onClose } />
            </div>
        </form>
    )
}