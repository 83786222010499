import React from "react";
import { useCallback } from "react";
import { Logo } from "../ui/Logo/Logo";
import { Button } from "../ui/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import styles from "./loginForm.module.css";
import { Input } from "../ui/Input/Input";
import { PasswordInput } from "../ui/PasswordInput/PasswordInput";
import { Link, Navigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { login } from "../../services/actions/auth";
import Loader from "../ui/Loader/Loader";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const { auth, loginFailed, loginSuccess, loginRequest } = useSelector(
    (store) => store.user
  );

  const [emailValue, setEmailValue] = React.useState("");
  const [passwordValue, setPasswordValue] = React.useState("");
  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      dispatch(
        login({
          email: emailValue,
          password: passwordValue,
        })
      );
    },
    [dispatch, emailValue, passwordValue]
  );

  const onChangeInput = (evt) => {
    if (evt.target.name === "login") {
      setEmailValue(evt.target.value);
      return;
    } else if (evt.target.name === "password") {
      setPasswordValue(evt.target.value);
      return;
    }
  };
  return (
    <>
      
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <Logo />
          {loginFailed && (
            <p className={styles.error}>Попытка авторизации не удалась, проверьте свои данные</p>
          )}
          <Input
            type="text"
            placeholder="Login"
            name="login"
            onChange={onChangeInput}
          />
          <PasswordInput
            placeholder="Password"
            name="password"
            onChange={onChangeInput}
          />
          <Link
            className={`${styles.forgotLink} link-default`}
            to={ROUTES.FORGOT_PASS}
            alt="Восстановить пароль"
            title="Восстановить пароль"
          >
            Забыли пароль?
          </Link>
          <Button value="Войти" type="submit" />
          <p className={styles.register}>
            У вас еще нет аккаунта?
            <br />
            <Link
              className={`${styles.registerLink} link-default`}
              to={ROUTES.REGISTER}
              alt="Зарегистрироваться"
              title="Зарегистрироваться"
            >
              Зарегистрироваться
            </Link>
          </p>
        </form>
      {loginRequest ? <Loader /> : ''}
      {loginSuccess || auth ? <Navigate to={ROUTES.MAIN} /> : ''}
    </>
  );
};
