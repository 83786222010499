import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import ModalOverlay from './ModalOverlay';
import styles from './modals.module.css';
import { CloseIcon } from '../ui/CloseIcon/CloseIcon'

const modalRoot = document.getElementById('root-modals');

const Modal = (props) => {
  useEffect(() => {
    const close = (evt) => {
      if (evt.key === 'Escape') {
        props.onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [props]);
  return createPortal(
    <ModalOverlay onClose={props.onClose}>
      <div
        className={`${styles.container} ${props.type ? styles.info : ''}`} 
        onClick={(evt) => evt.stopPropagation()}
      >
        {props.title && (
          <h3 className={styles.title}>
            {props.title}
          </h3>
        )}
        <button className={styles.closeButton} onClick={props.onClose}>
          <CloseIcon type="primary" />
        </button>
        {props.children}
      </div>
    </ModalOverlay>,
    modalRoot
  );
};

export default Modal;