import React, { useEffect, useState } from "react";
import { ABOUT_URL } from "../../utils/api-routes";
import Loader from "../ui/Loader/Loader";
import styles from './about.module.css';

export const About = () => {
    const [aboutData, setAboutData] = useState({
        loaded: false,
        error: false,
        data: [],
      });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(ABOUT_URL, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
    
                setAboutData({
                    loaded: true,
                    data: data,
                });
    
            } catch (error) {
                console.error('Error while fetching data:', error);
                setAboutData({
                    loaded: true,
                    error: true,
                    message: error.message,
                });
            }
        };
    
        fetchData();
    }, []);
    
    return (
        <>
            {aboutData.loaded &&  (
            <div className={styles.aboutContainer}>
                <h2 className={styles.mainTitle}>{aboutData.data.title}</h2>
                <p className={styles.otherTitle}>Версия: <span className={styles.baseText}>{aboutData.data.version}</span></p>
                <p className={styles.otherTitle}>Описание: <br /> <span className={styles.baseText}>{aboutData.data.description}</span></p>
                <p className={styles.otherTitle}>Основные функции:</p>
                <ul className={styles.featuresList}>
                    {aboutData.data.features.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
                <p className={styles.otherTitle}>Разработчик: <span className={styles.baseText}>{aboutData.data.developer}</span></p>
                <p className={styles.otherTitle}>Лицензия: <br /> <span className={styles.baseText}>{aboutData.data.license}</span></p>
            </div>    
            )}
            {!aboutData.loaded && <Loader />}
        </>
    )
}