import { refreshToken } from '../../utils/requestApi';

export const socketMiddleware = (wsActions) => (store) => {
    let socket = null;
    let reconnectTimer = null;
    let url = '';

    const connectToSocket = (url) => {
        socket = new WebSocket(url);
    };

    const closeSocket = () => {
        if (socket) {
            socket.close();
            socket = null;
        }
    };

    const handleOpen = (dispatch, onOpen) => () => {
        console.log('WebSocket connected');
        dispatch({ type: onOpen });
    };

    const handleError = (dispatch, onError) => (error) => {
        console.error('WebSocket error:', error);
        dispatch({ type: onError });
        reconnect(); // Попробуйте переподключиться при ошибке
    };

    const handleMessage = (dispatch, onMessage) => async (event) => {
        const { data } = event;
        const parsedData = JSON.parse(data);
        if (parsedData?.message === 'Invalid or missing token') {
            try {
                const newToken = await refreshToken();
                if (newToken.success) {
                    const newUrl = new URL(url);
                    newUrl.searchParams.set('token', newToken.accessToken);
                    closeSocket();  // Закрыть текущее соединение
                    url = newUrl.toString();  // Сохранить новый URL
                    connectToSocket(url);  // Подключиться заново с обновленным URL
                }
            } catch (error) {
                console.error('Failed to refresh token:', error);
                return;
            }
        }
        dispatch({ type: onMessage, payload: parsedData });
    };

    const handleClose = (dispatch, onClose) => () => {
        console.log('WebSocket closed');
        dispatch({ type: onClose });
        reconnect(); // После закрытия, попытаемся переподключиться
    };

    const reconnect = () => {
        if (reconnectTimer) {
            clearTimeout(reconnectTimer);
        }
        reconnectTimer = setTimeout(() => {
            if (url) {
                connectToSocket(url);  // Используем последний сохраненный URL
            }
        }, 3000); // Можете добавить экспоненциальную задержку для повторных попыток
    };

    return (next) => (action) => {
        const { dispatch } = store;
        const { type, payload } = action;
        const { wsConnect, wsDisconnect, onOpen, onClose, onError, onMessage } = wsActions;

        if (type === wsConnect) {
            if (typeof payload === 'string') {
                url = payload;  // Устанавливаем URL из action payload
            }
            closeSocket();  // Закрыть предыдущее соединение, если есть
            connectToSocket(url);

            socket.onopen = handleOpen(dispatch, onOpen);
            socket.onerror = handleError(dispatch, onError);
            socket.onmessage = handleMessage(dispatch, onMessage);
            socket.onclose = handleClose(dispatch, onClose);
        }

        if (type === wsDisconnect) {
            closeSocket();
            clearTimeout(reconnectTimer);
            dispatch({ type: onClose });
        }

        next(action);
    };
};