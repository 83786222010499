import { useState,useEffect } from 'react';
import styles from './report.module.css';
import { AccordeonStatusLabel } from './AccordeonStatusLabel';
import { AccordeonBar } from './AccordeonBar';

export const AccordeonItem = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [haveMutations, setHaveMutations] = useState(false);
    const { title, preparats, drugs, type } = props;
    const currentKeys = Object.keys(preparats);
    const sanitizedDrugs = sanitizeKeys(drugs);
    const filteredDrugs = filterObjectByKeys(sanitizedDrugs, currentKeys);
    function sanitizeKeys(originalObject) {
        const sanitizedObject = {};
    
        Object.keys(originalObject).forEach(key => {
            const sanitizedKey = key.replace(/\//g, '').replace(/r/g, '');
   
            sanitizedObject[sanitizedKey] = originalObject[key];
        });
    
        return sanitizedObject;
    }
    function filterObjectByKeys(originalObject, keysArray) {
        return keysArray.reduce((filteredObject, key) => {
            if (originalObject.hasOwnProperty(key)) {
                filteredObject[key] = originalObject[key];
            } else {
                console.log('lost key ', key);
            }
            return filteredObject;
        }, {});
    }

    useEffect(() => {
        if (Object.keys(filteredDrugs).length > 0) {
            setHaveMutations(Object.values(filteredDrugs).some(drug => drug.mutations.length > 0));
        }
    }, [filteredDrugs]);

    return (
        <div className={styles.accordeonItem}>
            <div className={styles.accordeonItemTitle}>
                <span className={styles.accordeonItemTitleText}>
                    {title}
                    {haveMutations && <span className={styles.accordeonItemMutationAlert}>!</span>}
                </span>
                <button className={styles.accordeonItemButton} onClick={() => setIsOpen(!isOpen)}>{isOpen ? (
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6 6L11 1" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                ) : (
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 11L6 6L1 1" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                )}</button>
            </div>
            {isOpen && <div className={styles.accordeonItemContent}>
                {Object.keys(preparats).map((key, index) => (
                    <div className={styles.accordeonItemContentItem} key={index}>
                        <span className={styles.accordeonItemContentItemTitle}>{preparats[key]}</span>
                        <AccordeonBar type={type} mutations={filteredDrugs[key]?.mutations} />
                        {filteredDrugs[key] && <AccordeonStatusLabel level={filteredDrugs[key].level} />}
                    </div>
                ))}
            </div>}
        </div>
    )
}