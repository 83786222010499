import { requestData, getUploadUrl } from '../../utils/requestApi';
import { CREATE_PROJECT_URL, GET_PROJECTS_URL } from '../../utils/api-routes';
import { startProject } from '../../utils/requestApi';

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';
export const MOVE_TO_ARCHIVED_REQUEST = 'MOVE_TO_ARCHIVED_REQUEST';
export const MOVE_TO_ARCHIVED_SUCCESS = 'MOVE_TO_ARCHIVED_SUCCESS';
export const MOVE_TO_ARCHIVED_FAILED = 'MOVE_TO_ARCHIVED_FAILED';
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED';
export const UPLOAD_FILES_START = 'UPLOAD_FILES_START';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILED = 'UPLOAD_FILES_FAILED';
export const DELETE_CURRENT_PROJECT = 'DELETE_CURRENT_PROJECT';
export const UPDATE_PROJECT_STATUS = 'UPDATE_PROJECT_STATUS';
export const CHANGE_CREATE_PROJECT_ID = 'CHANGE_CREATE_PROJECT_ID';
export const RESET_UPLOAD_FILES_STATUS = 'RESET_UPLOAD_FILES_STATUS';

export function getProjects(token) {
    return function (dispatch) {
        dispatch({
            type: GET_PROJECTS_REQUEST,
        });
        requestData(GET_PROJECTS_URL, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer  ${token}`,
            },
          })
            .then((res) => {
              if (res) {
                dispatch({
                  type: GET_PROJECTS_SUCCESS,
                  items: res,
                });
              } else {
                return Promise.reject(`Ошибка ${res.status}`);
              }
            })
            .catch(
              dispatch({
                    type: GET_PROJECTS_FAILED,
              })
            );
    }
}
export function createProject(token, title) {
    return function (dispatch) {
        dispatch({
            type: CREATE_PROJECT_REQUEST,
        });
        requestData(CREATE_PROJECT_URL, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer  ${token}`,
            },
            body: JSON.stringify({
                title: title,
            }),
        }).then((res) => {
            const creationDate = new Date().toISOString().split('T')[0];
            if (res && res.id) {
                dispatch({
                    type: CREATE_PROJECT_SUCCESS,
                    payload: {
                        id: res.id,
                        title: res.title,
                        created_date: creationDate,
                        status: 'NF',
                        files: [],
                    },
                });
            } else {
                return Promise.reject(`Ошибка ${res.status}`);
            }
        })
        .catch(
            dispatch({
                type: CREATE_PROJECT_FAILED,
            })
        );
    }   
}

export function uploadFiles(token, id, files) {
    return async function (dispatch) {
        dispatch({
            type: UPLOAD_FILES_START,
        });
        try {
            const uploadPromises = files.map(async (file) => {
                const url = await getUploadUrl(id, file.name, token);
                const response = await fetch(url, {
                    method: "put",
                    body: file,
                });

                if (!response.ok) {
                    throw new Error(`Ошибка ${response.status}`);
                }
            });

            await Promise.all(uploadPromises);

            dispatch({
                type: UPLOAD_FILES_SUCCESS,
                id: id,
            });
            startProject(token, id);

        } catch (error) {
            console.error('Ошибка загрузки файлов:', error);
            dispatch({
                type: UPLOAD_FILES_FAILED,
            });
        }
    }
}

export function moveProjectToArchived(token, id) {
    return function (dispatch) {
        const url = `${GET_PROJECTS_URL}${id}/to_archive/`;
        fetch(url, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer  ${token}`,
            },
        }).then((res) => {
            dispatch({
                type: MOVE_TO_ARCHIVED_SUCCESS,
            });
        }).catch((error) => {
            console.error('Ошибка перемещения проекта в архив:', error);
            dispatch({
                type: MOVE_TO_ARCHIVED_FAILED,
            });
        });
    }
}

export const updateProjectStatus = (projectId, status, statusText, percent) => ({
    type: UPDATE_PROJECT_STATUS,
    payload: { projectId, status, statusText, percent }
  });
