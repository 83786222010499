import {
    WS_CONNECTION_SUCCESS,
    WS_CONNECTION_ERROR,
    WS_CONNECTION_CLOSED,
    WS_GET_DATA,
    WS_CONNECTION_END,
  } from '../actions/websocket';
  
  export const websocket ={
    wsConnected: false,
    wsFailed: false,
    type: '',
    wsData: {},
  }
  
  export const wsReducer = (state = websocket, action) => {
    switch (action.type) {
      case WS_CONNECTION_SUCCESS:
        return {
          ...state,
          wsConnected: true,
          wsFailed: false,
        };
      case WS_CONNECTION_ERROR:
        return {
          ...state,
          wsConnected: false,
          wsFailed: true,
        };
      case WS_CONNECTION_CLOSED:
        return state;
      case WS_CONNECTION_END:
        return state;
      case WS_GET_DATA:
        return {
          ...state,
            wsData: action.payload,
        };
      default:
        return state;
    }
  };