import styles from './report.module.css';

export const ReportDetails = (props) => {
    const { virus_subtypes, coverages, mutations_resist } = props.data;
    const renderData = (items) => {
        if (items) {
            const limitedItems = items.slice(0, 2);
            const hasData = limitedItems.some(subArray => Object.values(subArray).some(subData => subData.length > 0));
            
            return (
            <div style={{maxWidth: '920px'}}>
                <span className={styles.alert}>
                {limitedItems.map((subArray, index) => (
                    Object.entries(subArray).map(([subKey, subData]) => (
                        subData.length > 0 && `${subData.join(', ')}${index !== limitedItems.length - 1 ? ', ':''}`
                    ))
                ))}
                </span>
                {hasData ? null : <span>Наличие мутаций не удалось определить</span>}
            </div>
            )
        } else {
            return <span>Наличие мутаций не удалось определить</span>
        }
    }
    return (
        <div className={styles.detailsWrapper}>
            <div className={styles.detailsMain}>
                <table className={styles.mainTable}>
                    <thead>
                        <tr>
                            <th>Результаты секвенирования</th>
                            <th>% покрытия</th>
                            <th>Наличие мутаций устойчивости к АРВТ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Покрытие гена протеазы</td>
                            <td>{coverages.PR_percent}%</td>
                            <td>{mutations_resist?.PR ? renderData(mutations_resist?.PR) : 'Не удалось определить'}</td>
                        </tr>
                        <tr>
                            <td>Покрытие гена обратной транскриптазы</td>
                            <td>{coverages.RT_percent}%</td>
                            <td>{mutations_resist?.RT ? renderData(mutations_resist?.RT) : 'Не удалось определить'}</td>
                        </tr>
                        <tr>
                            <td>Покрытие гена интегразы</td>
                            <td>{coverages.IN_percent}%</td>
                            <td>{mutations_resist?.IN ? renderData(mutations_resist?.IN) : 'Не удалось определить'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={styles.datailsRight}>
                <div className={styles.subtype}>
                    <div className={styles.subtypeTitle}>Субтип</div>
                    <div className={styles.subtypeText}>{virus_subtypes.blast}</div>
                </div>
                <div className={styles.subtype}>
                    <div className={styles.subtypeTitle}>Тропизм</div>
                    <div className={styles.subtypeText}>Не удалось определить</div>
                </div>
            </div>
        </div>
    )
}