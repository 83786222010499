import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./project-analisis.module.css";
import { Button } from "../ui/Button/Button";
import Modal from "../Modals/Modal";
import { CreateProject } from "../CreateProject/CreateProject";
import { CREATE_PROJECT_TEXT, UPLOAD_FILE_TITLE } from "../../utils/constants";
import { DeleteButton } from "../ui/DeleteButton/DeleteButton";
import ProgressBar from "../ui/ProgressBar/ProgressBar";
import StatusIcon from "../ui/StatusIcon/StatusIcon";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import FileUpload from "../UploadProjectFiles/UploadProjectFiles";
import { useNavigate } from 'react-router-dom';
import { createProject } from "../../services/actions/currentProjects";
import Loader from "../ui/Loader/Loader";
import { DELETE_CURRENT_PROJECT, CHANGE_CREATE_PROJECT_ID, RESET_UPLOAD_FILES_STATUS } from "../../services/actions/currentProjects";
import { WS_CONNECTION_START, WS_CONNECTION_END } from "../../services/actions/websocket";
import { DOMAIN } from "../../utils/api-routes";
import { getProjects } from "../../services/actions/currentProjects";
import { moveProjectToArchived } from "../../services/actions/currentProjects";
import { updateProjectStatus } from "../../services/actions/currentProjects";
import { ADD_PROJECT_TO_ARCHIVED } from "../../services/actions/archivedProjects";

const EmptyForm = (props) => {
  return (
    <div className={styles.emptyProjects}>
      <h3 className={styles.emptyTitle}>Нет проектов в очереди</h3>
      <Button value="Создать новый проект" onClick={props.callback} />
    </div>
  );
};
const ProjectList = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <ul className={styles.projectsList}>
        {props.projects
          .map((project, index) => (
          <li key={project.id} className={styles.project}>
            <div className={styles.projectMain}>
              <h3 className={styles.projectTitle}>
                {index + 1}. {project.title}
              </h3>
              {project.status === "AN" ? (
                <ProgressBar progress={project.percent ? project.percent : project.status_percent ? project.status_percent : 0} title={project.status_text} />
              ) : (
                ""
              )}
              {project.status === "ER" ? (
                <div className={styles.projectStatusWrapper}>
                  <StatusIcon status={false} />
                  <Button
                    styleHeight="narrow"
                    value="Посмотреть ошибку"
                    error="true"
                    transparent="true"
                    onClick={() => props.showError(project.status_text)}
                  />
                </div>
              ) : (
                ""
              )}
              {project.status === "RY" ? (
                <div className={styles.projectStatusWrapper}>
                  <StatusIcon status={true} />
                  <Button
                    styleHeight="narrow"
                    onClick={() => navigate(`/project/${project.id}`)}
                    value="ПЕРЕЙТИ К РЕЗУЛЬТАТАМ АНАЛИЗА"
                  />
                </div>
              ) : (
                ""
              )}
              {project.status === "NF" ? (
                <div className={styles.projectStatusWrapper}>
                  <StatusIcon status={true} />
                  <Button
                    styleHeight="narrow"
                    value="ЗАГРУЗИТЬ ФАЙЛЫ"
                    onClick={() => props.handleFileUpload(project.id)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <DeleteButton onClick={() => props.deleteProject(project)} />
          </li>
        ))}
      </ul>
    </>
  );
};

export const ProjectAnalysis = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [projectCreationStep, setProjectCreationStep] = useState(0);
  const { token } = useSelector((store) => store.user);
  const { items, createProjectRequest } = useSelector((store) => store.projectList);
  const {wsData} = useSelector((store) => store.websocket);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getProjects(token));
    }
  }, [dispatch, token]);
  
  useEffect(() => {
    dispatch({
      type: WS_CONNECTION_START,
      payload: `${DOMAIN}ws/user/?token=${token}`
    });
    return () => {
      dispatch({ type: WS_CONNECTION_END });
    };
  }, [dispatch, token]);

  useEffect(() => {
    if (wsData && wsData.type === "project_status") {
      console.log('wsData', wsData);
      const { project_id, status, text, percent } = wsData.data;
      dispatch(updateProjectStatus(project_id, status, text, percent));
    }
  }, [wsData, dispatch]);

  const showCreateModal = () => {
    setModalOpen(true);
  };
  const handleShowError = (msg) => {
    setShowError(true);
    setErrorMessage(msg);
  }
  const handleFileUpload = (id) => {
    setProjectCreationStep(1);
    dispatch({type: RESET_UPLOAD_FILES_STATUS});
    dispatch({type: CHANGE_CREATE_PROJECT_ID, payload: id});
    setModalOpen(true);
  }
  const handleCreateProject = (title) => {
    dispatch(createProject(token, title));
    setProjectCreationStep(1);
  };
  const finishedCreateProject = () => {
    setProjectCreationStep(0);
    setModalOpen(false)
  }
  const handleDeleteProject = (project) => {
    dispatch({type: DELETE_CURRENT_PROJECT, id: project.id});
    dispatch(moveProjectToArchived(token, project.id));
    dispatch({type: ADD_PROJECT_TO_ARCHIVED, payload: project});
  };
  return (
    <>
      <section className={styles.section}>
        <div className="glass-effect"></div>
        <h2 className={styles.title}>Анализ проектов</h2>
        <div className={styles.projects}>

          {items.length > 0 ? (
            <>
              <ProjectList
                projects={items}
                deleteProject={handleDeleteProject}
                showError={(msg) => handleShowError(msg)}
                handleFileUpload={(id) => handleFileUpload(id)}
              />
              <div className={styles.buttonWrapper}>
                <Button
                  value="Создать новый проект"
                  transparent="true"
                  textBlack="true"
                  onClick={showCreateModal}
                />
              </div>
            </>
          ) : (
            <EmptyForm callback={showCreateModal} />
          )}
        </div>
      </section>
      {isModalOpen && projectCreationStep === 1 ? (
        <Modal
          title={UPLOAD_FILE_TITLE}
          onClose={finishedCreateProject}
        >
          <FileUpload />
        </Modal>
      ) : (
        ""
      )}
      {isModalOpen && projectCreationStep === 0 ? (
        <Modal title={CREATE_PROJECT_TEXT} onClose={() => setModalOpen(false)}>
          <CreateProject onClick={(title) => handleCreateProject(title)} />
          {createProjectRequest && (
            <>
              <div className="glass-effect"></div>
              <Loader type="small" />
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
      {showError && (
        <ErrorMessage onClose={() => setShowError(false)}>
          <span>
            {errorMessage}
          </span>
        </ErrorMessage>
      )}
    </>
  );
};
