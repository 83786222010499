import { useEffect } from "react";
import styles from "./App.module.css";
import { ROUTES } from "../../utils/routes";
import { Routes, Route } from "react-router-dom";
import { Page404 } from "../../pages/404/404";
import { LoginPage } from "../../pages/login/login";
import { Main } from "../Main/Main";
import { useDispatch, useSelector } from "react-redux";
import { ForgotPasswordPage } from "../../pages/forgotPassword/forgotPasswordPage";
import { RegisterPage } from "../../pages/registerPage/registerPage";
import { getUserData } from "../../services/actions/auth";
import { tokenRefresh } from "../../services/actions/auth";
import { PrivateRoutes } from "../ProtectedRoute";
import { SetPasswordPage } from "../../pages/setPassword/setPasswordPage";
import { ReportMain } from "../Report/ReportMain";

function App() {
  const { token, auth, name } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth) {
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken && !token) {
        dispatch(tokenRefresh(refreshToken));
      }
      if (token && !name) {
        dispatch(getUserData(token));
      }
    }
  }, [dispatch, token, auth, name]);

  return (
    <>
      <Routes>
        <Route
          path={ROUTES.LOGIN}
          exact={true}
          element={
            <section className={styles.auth}>
              <div className="glass-effect"></div>
              <LoginPage />
            </section>
          }
        ></Route>
        <Route
          path={ROUTES.FORGOT_PASS}
          exact={true}
          element={
            <section className={styles.auth}>
              <div className="glass-effect"></div>
              <ForgotPasswordPage />
            </section>
          }
        ></Route>
        <Route path={ROUTES.RESET_PASS} element={
            <section className={styles.auth}>
            <div className="glass-effect"></div>
            <SetPasswordPage />
          </section>
        } />
        <Route
          path={ROUTES.REGISTER}
          exact={true}
          element={
            <section className={styles.auth}>
              <div className="glass-effect"></div>
              <RegisterPage />
            </section>
          }
        ></Route>
        <Route element={<PrivateRoutes />}>
          <Route path={ROUTES.PROJECT_DETAILS} element={<ReportMain />} />
          <Route path={ROUTES.MAIN} element={<Main />} />
        </Route>
        <Route
          path="*"
          element={
            <section className={styles.auth}>
              <div className="glass-effect"></div>
              <Page404 />
            </section>
          }
        ></Route>
      </Routes>
    </>
  );
}

export default App;
