import { useEffect } from 'react';
import styles from './report.module.css';
import { Header } from '../Header/Header';
import { ReportControls } from './ReportControls';
import { ReportGraph } from './ReportGraph';
import { ReportDetails } from './ReportDetails';
import { Accordeon } from './Accordeon';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReport } from '../../services/actions/archivedProjects';
import Loader from '../ui/Loader/Loader';

export const ReportMain = (props) => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    useEffect(() => {
        dispatch(getReport(token, id));
    }, [dispatch, token, id]);
    const {reportData, reportRequest, reportFailed, reportSuccess} = useSelector(state => state.archived);
    return (
        <section className={styles.container}>
            <Header />
            <div className={styles.report}>
                {reportSuccess && reportData && (
                    <>
                        <ReportControls id={id}/>
                        <ReportGraph data={reportData.depth} />
                        <ReportDetails data={reportData} />
                        <Accordeon drugs={reportData.drugs} />
                    </>
                )}
                {reportRequest && (
                    <Loader />
                )}
                {reportFailed && (
                    <span>Ошибка загрузки данных</span>
                )}
            </div>
        </section>
    )
}