import React, { useState } from "react";
import { Logo } from "../../components/ui/Logo/Logo";
import { Button } from "../../components/ui/Button/Button";
import styles from "./forgot-password.module.css";
import { Input } from "../../components/ui/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/ui/Loader/Loader";
import { restorePassword } from "../../services/actions/auth";

export const ForgotPassword = () => {
  const { resetRequest, resetSuccess, resetFailed } = useSelector(
    (store) => store.user
  );
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(restorePassword(email));
  };
  return (
    <>
      {!resetRequest ? (
        <>
          <Logo />
          {!resetSuccess && !resetFailed && (
            <form onSubmit={handleSubmit} className={styles.forgotForm}>
              <p className={styles.forgotText}>
                Для получения инструкций по восстановлению пароля введите,
                пожалуйста, email, который был использован при регистрации
              </p>
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
              <Button value="Отправить" type="submit" disabled={!email} />
            </form>
          )}
          {resetFailed && (
            <p className={styles.messageText}>
              Такой почты нет в системе или потеряна связь с сервером. <br />{" "}
              Проверьте данные и повторите попытку.
            </p>
          )}
          {resetSuccess && (
            <p className={styles.messageText}>
              Проверьте Вашу почту и перейдите по ссылке в письме, для
              восстановления пароля.
            </p>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
