import React from 'react';
import styles from './modals.module.css';

const ModalOverlay = (props) => {
  return (
    <aside className={styles.overlay} onClick={props.onClose}>
      {props.children}
    </aside>
  );
};

export default ModalOverlay;