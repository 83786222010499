import React from "react";
import styles from "./close-icon.module.css";

export const CloseIcon = (props) => {
  return (
    <span
      className={
        props.type === "primary" ? styles.closeIconPrimary : styles.closeIconSecondary
      }
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99999 11.7674L17.0712 18.8387C17.307 19.0664 17.6227 19.1924 17.9505 19.1895C18.2782 19.1867 18.5918 19.0552 18.8235 18.8235C19.0553 18.5917 19.1867 18.2782 19.1896 17.9504C19.1924 17.6227 19.0664 17.3069 18.8387 17.0712L11.7675 9.99994L18.8387 2.92869C19.0664 2.69294 19.1924 2.37719 19.1896 2.04944C19.1867 1.7217 19.0553 1.40818 18.8235 1.17642C18.5918 0.944659 18.2782 0.813198 17.9505 0.81035C17.6227 0.807502 17.307 0.933495 17.0712 1.16119L9.99999 8.23244L2.92874 1.16119C2.69193 0.939123 2.37801 0.817899 2.05341 0.82317C1.7288 0.828441 1.41898 0.959794 1.1895 1.18943C0.960026 1.41908 0.828892 1.72899 0.823851 2.05359C0.81881 2.3782 0.940256 2.69204 1.16249 2.92869L8.23249 9.99994L1.16124 17.0712C1.04185 17.1865 0.946627 17.3244 0.881116 17.4769C0.815605 17.6294 0.781122 17.7935 0.77968 17.9594C0.778237 18.1254 0.809864 18.29 0.872715 18.4436C0.935567 18.5973 1.02838 18.7368 1.14575 18.8542C1.26311 18.9715 1.40268 19.0644 1.5563 19.1272C1.70992 19.1901 1.87452 19.2217 2.04049 19.2203C2.20647 19.2188 2.37049 19.1843 2.523 19.1188C2.6755 19.0533 2.81343 18.9581 2.92874 18.8387L9.99999 11.7674Z"
          fill="#EFF0F8"
        />
      </svg>
    </span>
  );
};
