/**
 * 
 */
import React from "react";
import styles from './button.module.css';


export const Button = (props) => {
    const { type = "button", value, styleHeight, error, disabled, transparent, textBlack, additionalStyles } = props;
    let buttonStylesMods = styleHeight === "narrow" ? styles.narrow : styles.fixed;
    if (textBlack) {
        buttonStylesMods = buttonStylesMods? `${buttonStylesMods} ${styles.textBlack}`: styles.textBlack;
    }
    if (error) {
        buttonStylesMods = buttonStylesMods? `${buttonStylesMods} ${styles.error}`: styles.error;
    }
    return (
        <button 
            className={ `${styles.default} ${buttonStylesMods} ${transparent?styles.transparent:''}` } 
            type = { type } 
            onClick={props.onClick}
            disabled = {disabled}
            style={additionalStyles}
            >
                { value }
            </button>
    )

}