import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getArchivedProjects, deleteArchivedProject } from "../../services/actions/archivedProjects";
import styles from "./project-history.module.css";
import { SearchInput } from "../ui/SearchInput/SearchInput";
import { DeleteButton } from "../ui/DeleteButton/DeleteButton";
import { LAST_CHANGES_TEXT } from "../../utils/constants";
import { DeleteProject } from "../DeleteProject/DeleteProject";
import Modal from "../Modals/Modal";
import { Pagination } from "../Pagination/Pagination";
import { Link } from "react-router-dom";
import Loader from "../ui/Loader/Loader";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

const ProjectHistoryItems = ({ items, itemsPerPage, currentPage }) => (
  <ul className={styles.itemsList}>
    {items
      .map((item, index) => (
      <ProjectHistoryItem
        key={item.id}
        number={itemsPerPage * (currentPage - 1) + index + 1}
        title={item.title}
        createdDate={item.created_date}
        status={item.status}
        statusText={item.status_text}
        id={item.id}
      />
    ))}
  </ul>
);

const ProjectHistoryItem = ({ number, title, createdDate, id, status, statusText }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const { token } = useSelector(store => store.user);

  const date = new Date(createdDate);
  const formattedDate = `${String(date.getUTCDate()).padStart(2, "0")}.${String(date.getUTCMonth() + 1).padStart(2, "0")}.${date.getUTCFullYear()}`;

  const handleDeleteClick = (projectId) => {
    dispatch(deleteArchivedProject(token, projectId));
    setModalOpen(true);
  };

  return (
    <li className={styles.listItem} key={id}>
      <span>{number}.</span>
      <p>
        {status === 'ER' || status === 'NF' ? (
          <span>
            <Link
              onClick={() => setShowError(true)}
              className={`${styles.listLink} link-default`}
              title="Project details"
              style={{color: '#EB3939'}}
            >
              {title}
          </Link>
          </span>
        ) : ('')}
        {status !== 'ER' && status !== 'NF' ? (
          <Link
            to={`/project/${id}`}
          className={`${styles.listLink} link-default`}
          title="Project details"
        >
            {title}
          </Link>
        ) : ('')}
        <span>
          ({LAST_CHANGES_TEXT} {formattedDate})
        </span>
      </p>
      <DeleteButton onClick={() => setModalOpen(true)} />
      {isModalOpen && (
        <Modal type="info" onClose={() => setModalOpen(false)}>
          <DeleteProject onClose={() => setModalOpen(false)} onConfirm={() => handleDeleteClick(id)} id={id} />
        </Modal>
      )}
      {showError && (
        <ErrorMessage onClose={() => setShowError(false)}>
          <span>
            {statusText}
          </span>
        </ErrorMessage>
      )}
    </li>
  );
};

export const ProjectHistory = () => {
  const { items, itemsRequest, itemsFailed } = useSelector(store => store.archived);
  const { token } = useSelector(store => store.user);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);

  useEffect(() => {
    if (token) {
      dispatch(getArchivedProjects(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const availableHeight = window.innerHeight - 430; 
      setItemsPerPage(Math.floor(availableHeight / 62)); 
    };

    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredItems = items.filter(item =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const displayedItems = filteredItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <section className={styles.section}>
      <div className="glass-effect"></div>
      <h2 className={styles.title}>История проектов</h2>
      <SearchInput onChange={handleSearchChange} value={searchTerm} />
      <div className={styles.projects}>
        {displayedItems.length > 0 && !itemsRequest && !itemsFailed ? (
          <>
            <ProjectHistoryItems items={displayedItems} itemsPerPage={itemsPerPage} currentPage={currentPage}/>
            {totalPages > 1 && <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />}
          </>
        ) : (
          <p className={styles.msgText}>Нет проектов в архиве</p>
        )}
        {itemsRequest && <Loader type="small" />}
        {itemsFailed && !itemsRequest && <p className={styles.msgText}>Ошибка загрузки проектов</p>}
      </div>
    </section>
  );
};