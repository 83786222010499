import React from "react";
import styles from './error-message.module.css';
import { createPortal } from 'react-dom';
import { CloseIcon } from "../ui/CloseIcon/CloseIcon";

export const ErrorMessage = (props) => {
    const messageRoot = document.getElementById('root-messages');
    return createPortal (
        <aside className={styles.message}>
            <button className={styles.closeButton} onClick={props.onClose}>
                <CloseIcon type="secondary" />
            </button>
            <p className={styles.messageText}>{props.children}</p>
        </aside>, messageRoot
    )
}