import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Loader from './ui/Loader/Loader';
export const PrivateRoutes = () => {
  const { token, tokenRequest, getUserRequest, id, loginRequest } = useSelector((store) => store.user);
  const isLoading = tokenRequest || getUserRequest || loginRequest;
  const refreshToken = localStorage.getItem("refreshToken");
  const mustLogin = !token && !refreshToken;
  return (
    <>
      {isLoading && <Loader />}
      {!mustLogin && !isLoading && <Outlet />}
      {mustLogin && <Navigate to='/login' />}

    </>
  )
}