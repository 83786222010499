import React from 'react';
import styles from './report.module.css';

function parseString(inputString) {
    const match = inputString.match(/^([^0-9]*)(\d+)(.*)$/);

    if (!match) {
        return { before: '', number: '', after: '' };
    }

    const [, before, number, after] = match;
    return { before, number, after };
}

export const AccordeonBarMarker = (props) => {
    const { mutation } = props;
    const { before, number, after } = parseString(mutation);

    return (
        <div className={styles.stringContainer}>
            <span className={styles.markerBefore}>{before}</span>
            <span className={styles.markerNumber}>{number}</span>
            <span className={styles.markerAfter}>
                {
                    [...after].map((char, index) => { 
                        return (
                        <span key={index}>{char}</span>
                    )})
                }
            </span>
        </div>
    );
};