import React from "react";
import { useState } from "react";
import { Logo } from "../../components/ui/Logo/Logo";
import { Button } from "../../components/ui/Button/Button";
import styles from "./registerForm.module.css";
import { Input } from "../../components/ui/Input/Input";
import { PasswordInput } from "../../components/ui/PasswordInput/PasswordInput";
import { CheckBox } from "../../components/ui/CheckBox/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { registration } from "../../services/actions/auth";
import Loader from "../../components/ui/Loader/Loader";
import { Link } from "react-router-dom";
import { VALIDATION_ERRORS } from "../../utils/constants";

export const RegisterForm = () => {
  const dispatch = useDispatch();
  const { registrationRequest, registrationSuccess, registrationFailed } =
    useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    post: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      agreeTerms: e.target.checked,
    });
  };

  const validate = () => {
    let validationErrors = {};
    const { password, confirmPassword } = formData;

    if (!formData.name) {
      validationErrors.username = VALIDATION_ERRORS.USERNAME;
    }
    if (!formData.organization) {
      validationErrors.organization = VALIDATION_ERRORS.ORGANIZATION;
    }
    if (!formData.post) {
      validationErrors.post = VALIDATION_ERRORS.POST;
    }
    if (!formData.email) {
      validationErrors.email = VALIDATION_ERRORS.EMAIL;
    }
    if (!formData.password) {
      validationErrors.password = "Введите пароль";
    } else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[a-z]).{8,}$/.test(password)) {
      validationErrors.password = VALIDATION_ERRORS.PASSWORD_WRONG;
    }
    if (password !== confirmPassword) {
      validationErrors.confirmPassword = VALIDATION_ERRORS.CONFIRM_PASSWORD;
    }
    if (!formData.agreeTerms) {
      validationErrors.agreeTerms = VALIDATION_ERRORS.AGREE_TERMS;
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (validate()) {
      dispatch(
        registration({
          email: formData.email,
          password: formData.password,
          name: formData.name,
          organization: formData.organization,
          post: formData.post,
        })
      );
    }
  };

  const isFormValid = Object.values(formData).every(Boolean);

  return (
    <>
      {!registrationSuccess && !registrationFailed ? (
        <form onSubmit={handleSubmit} className={styles.registerForm}>
          <Logo />
          <Input
            type="text"
            placeholder="ФИО"
            name="name"
            value={formData.name}
            onChange={handleChange}
            maxLength="450"
          />
          {errors.username && (
            <span className={styles.error}>{errors.username}</span>
          )}
          <Input
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            maxLength="254"
          />
          {errors.email && <span className={styles.error}>{errors.email}</span>}
          <Input
            type="text"
            placeholder="Наименование организации"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            maxLength="450"
          />
          {errors.organization && (
            <span className={styles.error}>{errors.organization}</span>
          )}
          <Input
            type="text"
            placeholder="Должность"
            name="post"
            value={formData.post}
            onChange={handleChange}
            maxLength="100"
          />
          {errors.post && <span className={styles.error}>{errors.post}</span>}
          <PasswordInput
            placeholder="Пароль"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && (
            <span className={styles.error}>{errors.password}</span>
          )}
          <PasswordInput
            placeholder="Повторите пароль"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          {errors.confirmPassword && (
            <span className={styles.error}>{errors.confirmPassword}</span>
          )}
          <CheckBox
            name={formData.agreeTerms}
            checked={formData.agreeTerms}
            onChange={handleCheckboxChange}
          >
            Я согласен на 
            <Link to="/docs/soglasie" target="_blank" className={styles.agreedLink}> обработку персональных данных</Link>
          </CheckBox>
          {errors.agreeTerms && (
            <span className={styles.error}>{errors.agreeTerms}</span>
          )}
          <Button
            value="Зарегистрироваться"
            type="submit"
            disabled={!isFormValid}
          />
        </form>
      ) : (
        ""
      )}
      {registrationRequest && <Loader />}
      {registrationSuccess && (
        <>
          <Logo />
          <p className={styles.success}>Вы успешно прошли регистрацию!</p>
          <p className={styles.success}>
            Для активации аккаунта следуйте инструкциям, мы отправили их на ваш
            email, который был использован при регистрации.
          </p>
        </>
      )}
      {registrationFailed && (
        <>
          <Logo />
          <p className={styles.error}>
            Произошла ошибка при регистрации. Попробуйте еще раз через некоторое
            время.
          </p>
        </>
      )}
    </>
  );
};
