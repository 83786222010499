export const LAST_CHANGES_TEXT = 'Последние изменения';
export const CREATE_PROJECT_TEXT = 'Создать проект';
export const DELETE_PROJECT_TEXT = 'Вы уверены, что хотите удалить данный проект навсегда?'
export const UPLOAD_FILE_TITLE = 'Добавление файлов fastq';
/* Ошибки валидации */
export const VALIDATION_ERRORS = {
    REQUIRED: 'Поле обязательно для заполнения',
    EMAIL: 'Введите корректный email',
    PASSWORD: 'Введите пароль',
    PASSWORD_WRONG: 'Пароль должен содержать минимум 8 символов латиницей, буквы и цифры, и хотя бы одну заглавную букву.',
    CONFIRM_PASSWORD: 'Пароли не совпадают',
    USERNAME: 'Введите имя пользователя',
    ORGANIZATION: 'Введите имя организации',
    POST: 'Введите вашу должность',
    AGREE_TERMS: 'Вы должны согласиться с обработкой персональных данных',
}
export const ACCORDEON_DATA = {
    'NRTI': {
        title: 'Нуклеозидные ингибиторы обратной транскриптазы (НИОТ/NRTI)',
        description: 'Нуклеозидные ингибиторы обратной транскриптазы (НИОТ/NRTI) — это группа антиретровирусных препаратов, которые блокируют фермент обратной транскриптазы (RT), необходимый для синтеза вирусной ДНК. Они широко используются в лечении ВИЧ-инфекции.',
        preparats: {
            '3TC': 'Ламивудин',
            'ABC': 'Абакавир',
            'D4T': 'Ставудин',
            'DDI': 'Диданозин',
            'FTC': 'Емтрицитабин',
            'TDF': 'Тенофовир',
            'TAF': 'Тенофовир алафенамид',
            'PhAZT': 'Фосфазид',
            'AZT': 'Зидовудин',
        }
    },
    'NNRTI': {
        title: 'Ненуклеозидные ингибиторы обратной транскриптазы (ННИОТ/NNRTI)',
        description: 'Ненуклеозидные ингибиторы обратной транскриптазы (ННИОТ/NNRTI) — это группа антиретровирусных препаратов, которые блокируют фермент обратной транскриптазы (RT), необходимый для синтеза вирусной ДНК. Они широко используются в лечении ВИЧ-инфекции.',
        preparats: {
            'DOR': 'Доравудин',
            'EFV': 'Эфавиренз',
            'ETR': 'Этравирин',
            'RPV': 'Рилпивирин',
            'NVP': 'Невирапин',
            'ESV': 'Элсульфавирин',
        }
    },
    'PI': {
        title: 'Ингибиторы протеазы (ИП/Pls)',
        description: 'Ингибиторы протеазы (ИП/Pls) — это группа антиретровирусных препаратов, которые блокируют фермент протеазы (PL), необходимый для синтеза вирусной ДНК. Они широко используются в лечении ВИЧ-инфекции.',
        preparats: {
            'ATV': 'Атазанавир',
            'DRV': 'Дарунавир',
            'LPV': 'Лопинавир',
            'FPV': 'Фосампренавир',
            'NFV': 'Нелфинавир',
            'SQV': 'Саквинавир',
            'TPV': 'Типранавир',
            'IDV': 'Инвидавир',
            //'RTV': 'Ритонавир',
        }
    },
    'INSTI': {
        title: 'Ингибиторы интегразы (ИИ)',
        description: 'Ингибиторы интегразы (ИИ) — это группа антиретровирусных препаратов, которые блокируют фермент интегразы (INST), необходимый для синтеза вирусной ДНК. Они широко используются в лечении ВИЧ-инфекции.',
        preparats: {
            'BIC': 'Биктегравир',
            'DTG': 'Долутегравир',
            'CAB': 'Каботегравир',
            'EVG': 'Элвитегравир',
            'RAL': 'Ралтегравир',
        }
    },
    'IN': {
        title: 'Ингибиторы проникновения',
        description: 'Ингибиторы проникновения — это группа антиретровирусных препаратов, которые блокируют фермент проникновения (IN), необходимый для синтеза вирусной ДНК. Они широко используются в лечении ВИЧ-инфекции.',
        preparats: {
            'ENF': 'Энфувиртид',
            'MVC': 'Мевиравир',
        }
    }
};
export const ACCORDEON_STATUS_LABEL = {
    '0': 'Нет данных',
    '1': 'Нет резистентности',
    '2': 'Потенциально низкая резистентность',
    '3': 'Низкая резистентность',
    '4': 'Средняя резистентность',
    '5': 'Высокая резистентность',
}
export const BAR_MAX_SCALE = 250;
