import React, { useState } from "react";
import { Input } from "../ui/Input/Input";
import { Button } from "../ui/Button/Button";
import styles from './create-project.module.css';
import { CheckBox } from "../ui/CheckBox/CheckBox";
import { CREATE_PROJECT_TEXT } from "../../utils/constants";

export const CreateProject = (props) => {
    const progectId = 'Project_1234567890';
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({
        projectName: '',
        agreed: false,
    });
    const [errorMessage, setErrorMessage] = useState('');

    const checkCyrillic = (text) => /[\u0400-\u04FF]/.test(text);

    const checkButtonStatus = (evt) => {
        const { name, value } = evt.target;
        
        let error = '';
        if (checkCyrillic(value)) {
            error = 'Запрещено использовать кириллицу для имени проекта.';
            setButtonDisabled(true);
        } else if (value && formData.agreed) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }

        setErrorMessage(error);
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleCheckboxChange = (evt) => {
        const { name, checked } = evt.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
        if (checked && formData.projectName && !checkCyrillic(formData.projectName)) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }

    return (
        <form className={styles.form}>
            <div className={styles.inputWrapper}>
                <Input 
                    type="text" 
                    placeholder={progectId} 
                    name="projectName" 
                    onChange={checkButtonStatus}
                    maxLength={200}
                />
            {errorMessage && <div className={styles.error}>{errorMessage}</div>}
            </div>
            <CheckBox onChange={handleCheckboxChange} name="agreed">
                Подтверждаю, что в названии проекта не фигурируют персональные данные
            </CheckBox>
            <Button 
                value={CREATE_PROJECT_TEXT} 
                disabled={buttonDisabled} 
                onClick={() => props.onClick(formData.projectName)} 
            />
        </form>
    )
}